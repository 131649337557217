<template>
  <div class="layout">
    <nav-top-panel class="px-2" />
    <main>
      <slot />
    </main>
    <footer />
  </div>
</template>

<style lang="postcss" scoped>
.layout {
  @apply flex flex-col items-stretch
}
main {
  @apply flex-grow
}
footer {
  &:empty {
    display: none;
  }
  @apply sticky
  w-full
  bottom-0 left-0 right-0
  border-t border-t-gray-200 dark:border-t-amber-100
  z-50
  text-sm
  backdrop-blur
}
</style>
