<script setup lang="ts">
</script>

<template>
  <header>
    <nav-top-panel-general-navigation class="mr-3 order-1" />
    <div id="top-nav-middle" class="flex-grow order-4 md:order-2 w-full md:w-auto mt-1 md:mt-0" />
    <div class="flex items-center space-x-3 order-3">
      <client-only>
        <nav-widget-color-mode />
        <nav-widget-lang-switcher id="top-menu-lang-switcher" />
      </client-only>
      <template v-if="isAuthenticated()">
        <nav-widget-notifications v-if="false" id="top-menu-notifications" />
        <nav-widget-auth id="top-menu-auth" />
      </template>
      <template v-else>
        <UButton :to="useUrlWithRedirect('/auth/login')" variant="link">
          {{ $t('nav.login') }}
        </UButton>
        <UButton :to="useUrlWithRedirect('/auth/register')" variant="outline">
          {{ $t('nav.register') }}
        </UButton>
      </template>
    </div>
  </header>
</template>

<style lang="postcss" scoped>
header {
  @apply sticky
  flex items-center justify-between
  flex-wrap md:flex-nowrap
  backdrop-blur
  border-b border-b-gray-200 dark:border-b-amber-100
  top-0
  z-20
  text-sm
  py-1
  shadow
}
</style>
