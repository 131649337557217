<script setup lang="ts">
import { logout } from '#imports';

const { t } = useI18n();
const user = getAuthUser();
const isOpen = ref(false);

async function onCopyEmail() {
  if (!user) {
    return;
  }
  if (await copyTextToClipboard(user.email)) {
    useToast().add({
      title: t('nav.authWidget.emailCopied'),
      icon: 'i-heroicons-clipboard-document-check',
      timeout: 2000,
    });
    isOpen.value = false;
  }
}

async function logoutHandler() {
  await logout();
  isOpen.value = false;
}

// todo listen ws events
// onMounted(() => {
//   echo()
//     .private('App.Models.User.' + user.id)
//     .notification((notification: object) => {
//       logger().debug('[notify]', notification);
//     });
// });
// onBeforeUnmount(() => {
//   echo().leave('App.Models.User.' + user.id);
// });
const buttonUI = {
  inline: 'w-full flex items-center',
};
</script>

<template>
  <UPopover v-model:open="isOpen" :popper="{ offsetDistance: 6, placement: 'bottom-end' }">
    <UButton variant="outline" icon="i-heroicons-user-circle-16-solid" padded />
    <template #panel>
      <div v-if="user" class="p-2 space-y-2">
        <UTooltip :text="$t('nav.authWidget.copyEmail')" :open-delay="1000">
          <UButton
            variant="ghost"
            color="white"
            :ui="buttonUI"
            @click="onCopyEmail"
          >
            <span>
              <strong class="block text-lg">{{ user.name }}</strong>
              <span class="opacity-80">{{ user.email }}</span>
            </span>
          </UButton>
        </UTooltip>
        <UButton
          variant="ghost"
          color="gray"
          to="/settings/profile"
          icon="i-heroicons-user-circle-20-solid"
          :ui="buttonUI"
          @click="isOpen = false"
        >
          {{ $t('nav.authWidget.settings') }}
        </UButton>
        <UButton
          variant="ghost"
          color="gray"
          icon="i-heroicons-arrow-left-on-rectangle"
          :ui="buttonUI"
          @click="logoutHandler"
        >
          {{ $t('nav.authWidget.logout') }}
        </UButton>
      </div>
    </template>
  </UPopover>
</template>
