<script setup lang="ts">
import type { VerticalNavigationLink } from '#ui/types';
import { can } from '#imports';
import { Ability, Project } from '~/models';

const { t } = useI18n();
const isOpen = ref(false);
const route = useRoute();
const items = computed(() => {
  const items: VerticalNavigationLink[][] = [
    [
      {
        label: t('nav.home'),
        icon: 'i-bi-house-fill',
        to: '/',
      },
    ],
  ];
  if (can(Ability.ADMIN_VIEW)) {
    items.push([
      {
        label: t('nav.admin'),
        icon: 'i-bi-bar-chart-fill',
        to: '/admin',
      },
    ]);
  }

  items.push([
    {
      label: t('nav.projects'),
      icon: 'i-bi-card-list',
      to: '/projects',
    },
  ]);

  const projectSlug = route.params.projectSlug as string | undefined;
  if (projectSlug) {
    const project = useRepo(Project).where('slug', projectSlug).first();
    if (project) {
      const projectLinks = [
        {
          label: project.name,
          icon: 'i-bi-box',
          to: `/projects/${projectSlug}`,
        },
        {
          label: t('nav.projectEvents'),
          icon: 'i-bi-caret-right',
          to: `/projects/${projectSlug}/events`,
        },
      ];
      if (can(Ability.SETTINGS_VIEW, project.id)) {
        projectLinks.push({
          label: t('nav.projectSettings'),
          icon: 'i-bi-caret-right',
          to: `/projects/${projectSlug}/settings`,
        });
      }

      if (can(Ability.USERS_VIEW, project.id)) {
        projectLinks.push({
          label: t('nav.projectUsers'),
          icon: 'i-bi-caret-right',
          to: `/projects/${projectSlug}/users`,
        });
      }

      items.push(projectLinks);
    }
  }

  return items;
});

watch(() => route.fullPath, close);

function close() {
  isOpen.value = false;
}
</script>

<template>
  <UPopover
    v-model:open="isOpen"
    :popper="{ offsetDistance: 6, placement: 'bottom-start' }"
  >
    <div class="flex items-center space-x-2">
      <nav-logo-horizontal />
      <UIcon name="i-bi-list" />
    </div>

    <template #panel>
      <UVerticalNavigation :links="items" @click="close" />
    </template>
  </UPopover>
</template>
